import { OrientationType } from "@/interfaces/device";

export const typeDevices = {
	1: {
		// Banner Image
		enableRotate: false,
		isRotate: false,
		rotateDefault: OrientationType.VERTICAL,
		type: "image",
	},
	3: {
		// VastInline
		enableRotate: true,
		isRotate: true,
		rotateDefault: OrientationType.HORIZONTAL,
		type: "video",
	},
	4: {
		// JsTag
		enableRotate: false,
		isRotate: false,
		rotateDefault: OrientationType.VERTICAL,
		type: "iframe",
	},
	5: {
		// IframeTag
		enableRotate: false,
		isRotate: false,
		rotateDefault: OrientationType.VERTICAL,
		type: "animate",
	},
	6: {
		// VastWrapper
		enableRotate: true,
		isRotate: true,
		rotateDefault: OrientationType.VERTICAL,
		type: "video",
	},
	13: {
		// MraidTag
		enableRotate: false,
		isRotate: false,
		rotateDefault: OrientationType.VERTICAL,
		type: "animate",
	},
	20: {
		// VastWrapper WithMOATViewability
		enableRotate: true,
		isRotate: true,
		rotateDefault: OrientationType.VERTICAL,
		type: "video",
	},
	21: {
		// HTML5
		enableRotate: false,
		isRotate: false,
		rotateDefault: OrientationType.VERTICAL,
		type: "html",
	},
	23: {
		// VastInline
		enableRotate: true,
		isRotate: true,
		rotateDefault: OrientationType.HORIZONTAL,
		type: "video",
	},
};

export function getDeviceOption(type_id: number) {
	return typeDevices[type_id];
}

export function getMatchedTemplates(templates: any[]) {
	return templates
		.filter((t: { id: number }) => t.id)
		.map((t: { id: number; value: any }) => {
			return { [t.id]: t.value };
		});
}
